import {
  GoogleAuthProvider,
  getAuth,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
  linkWithCredential,
  AuthCredential,
  UserCredential,
  getAdditionalUserInfo,
} from 'firebase/auth';

import {
  createCustomToken,
  getUser,
  updateUserName,
  verifyEmail,
} from '@api/userApi';
import { ActionType, provider } from '@/types/auth';
import { sharedData } from '@shares/dataManager';
import { PAGE_PATHS } from '@constants/url';
import { ACTION_TYPES } from '@/constants/actions';
import getLastVisitedService from '@/utils/getLastVisitedService';
import { CAMERAFI_STUDIO } from '@/constants/services';
import getGaClientToken from '@/utils/getGaClientToken';

const auth = getAuth();

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: 'select_account' });
const facebookAuthProvider = new FacebookAuthProvider();
const appleAuthProvider = new OAuthProvider('apple.com');
appleAuthProvider.addScope('email');
appleAuthProvider.addScope('name');

const authProvidersMap: {
  [key in provider]: GoogleAuthProvider | FacebookAuthProvider | OAuthProvider;
} = {
  google: googleAuthProvider,
  facebook: facebookAuthProvider,
  apple: appleAuthProvider,
};

const linkAccountWithPopup = async (
  pendingCred: AuthCredential,
  signInMethod: provider,
) => {
  const result = await signInWithPopup(auth, authProvidersMap[signInMethod]);
  const credential = await linkWithCredential(result.user, pendingCred);

  return credential;
};

const getIsNewUser = (userCredential: UserCredential) => {
  const additionalUserInfo = getAdditionalUserInfo(userCredential);

  let isNewUser = false;
  if (additionalUserInfo) isNewUser = additionalUserInfo.isNewUser;

  return isNewUser;
};

const idTokenHandler = async ({
  idToken,
  actionType,
  nickname,
  isNewUser,
}: {
  idToken: string;
  actionType: keyof ActionType;
  nickname?: string;
  isNewUser?: boolean;
}) => {
  const gaClientToken = getGaClientToken();

  const {
    data: { token: customToken },
  } = await createCustomToken({
    idToken,
    params: {
      lastVisitedService:
        sharedData.queries.platform !== null &&
        getLastVisitedService(sharedData.queries.platform) === CAMERAFI_STUDIO
          ? CAMERAFI_STUDIO
          : undefined,
      isNewUser: isNewUser ? 'true' : undefined,
      gaClientId: gaClientToken,
    },
  });

  if (actionType === ACTION_TYPES.EMAIL_SIGNUP && nickname) {
    await updateUserName({
      idToken,
      nickname,
    });

    await verifyEmail(idToken);
  }

  if (sharedData.queries.platform === 'playStore') {
    const {
      data: { uid, userName },
    } = await getUser(idToken);

    let url = new URL(PAGE_PATHS.DELETE_AGREEMENT, window.location.href);
    url.searchParams.append('platform', 'playStore');
    url.searchParams.append('uid', uid);
    url.searchParams.append('userName', userName);
    url.searchParams.append('api', idToken);

    window.location.href = url.href;
  }

  return {
    customToken,
  };
};

// 카메라파이 쇼핑에서 통합로그인 페이지를 사용하는 경우
const isCalledFromParentSite = !sharedData.queries.callbackUrl && window.opener;

export {
  appleAuthProvider,
  auth,
  authProvidersMap,
  facebookAuthProvider,
  getIsNewUser,
  googleAuthProvider,
  idTokenHandler,
  linkAccountWithPopup,
  isCalledFromParentSite,
};
